var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-overlay',{attrs:{"show":_vm.isShowLoading}},[_c('b-card',{attrs:{"title":_vm.$t('master.department.form')}},[_c('validation-observer',{ref:"validateForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveData($event)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('master.location.name') + '*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('master.location.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? 'vue-select is-invalid' : null,attrs:{"label":"locationName","options":_vm.optionLocations,"reduce":function (option) { return option.locationId; }},model:{value:(_vm.locationId),callback:function ($$v) {_vm.locationId=$$v},expression:"locationId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('master.department.name') + '*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('master.department.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.departmentName),callback:function ($$v) {_vm.departmentName=$$v},expression:"departmentName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('master.department.areaOwner') + '*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('master.department.areaOwner'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? 'vue-select is-invalid' : null,attrs:{"label":"userFullName","options":_vm.userAreaOwnerLists,"reduce":function (option) { return option.userId; },"multiple":""},model:{value:(_vm.departmentOwner),callback:function ($$v) {_vm.departmentOwner=$$v},expression:"departmentOwner"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"text-center"},[_c('b-button',{staticClass:"my-1",attrs:{"type":"submit","variant":"success","disabled":_vm.isBtnDisabled}},[(_vm.isBtnDisabled)?_c('b-spinner',{attrs:{"small":""}}):_c('feather-icon',{attrs:{"icon":"SaveIcon"}}),_vm._v(" "+_vm._s(_vm.$t('general.btnSubmit'))+" ")],1)],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }